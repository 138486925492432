<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-15 09:39:12
 * @LastEditTime: 2023-03-15 16:35:29
 * @Descripttion: 【内容-教材管理】章节管理
-->
<style lang="scss" scoped>
.textbook-chapters {
    @include innerPage(40px 32px 48px 15px);
    @include pageTitle(0, 41.34%);
    @include defalutTable(calc(100% - 42px), 20px);

    .page-title {
        .search-box {
            margin-right: 112px;

            ::v-deep .el-input {
                &__inner {
                    height: 46px;
                    line-height: 46px;
                    background: #f1f2f8;
                    font-size: 14px;
                    padding-left: 54px;
                }

                &__prefix {
                    left: 22px;
                }
            }
        }

        .textbook-info {
            margin-right: auto;
            margin-left: 24px;

            ::v-deep .el-breadcrumb {

                &__inner,
                &__separator {
                    font-size: 18px;
                    color: #222;
                }

                &__separator {
                    margin: 0 10px;
                }
            }
        }
    }

    .table-tree {
        .size--2 {
            width: 160px;

            .el-button--text {
                margin-right: 34px;

                &:hover {
                    color: #6c4ecb;

                    .add {
                        color: #fff;
                        background: #6c4ecb;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.dialog-container {
    box-sizing: border-box;
    padding: 34px 20px 48px 62px;

    .el-form-item {
        width: 100%;
    }

    .el-form-item:last-child {
        margin-bottom: 0;
    }
}
</style>

<template>
    <section class="textbook-chapters">
        <div class="page-title">
            <h3>章节管理</h3>
            <div class="textbook-info">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item>{{ textbookInfo.sccou_grade_name }}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ textbookInfo.semester_name }}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ textbookInfo.sysub_name }}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ textbookInfo.syedi_title }}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ textbookInfo.syedi_version }}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <el-button type="custom_primary" size="medium" @click="showDialog">
                新增章+
            </el-button>
            <el-button type="custom_primary" size="medium" @click="$router.go(-1)">返回</el-button>
        </div>
        <div class="table-box">
            <div class="table-tree" ref="tableInner">
                <el-table :data="tableData" height="100%" row-key="sccou_cha_id" border default-expand-all
                    :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" :indent='0'
                    :row-class-name="rowClassName">
                    <el-table-column align="center" prop="sccou_cha_title" label="章" min-width="17.39%" type="" />
                    <el-table-column align="center" prop="joint_title" label="节" min-width="27.26%" />
                    <el-table-column align="center" label="添加时间" min-width="15.22%">
                        <template slot-scope="scope">
                            {{ scope.row.create_time * 1000 | formatTime }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="状态" min-width="13.77%">
                        <template slot-scope="scope">
                            <p class="table-status" v-if="scope.row.sccou_cha_id > 0"
                                :class="{ failed: scope.row.sccou_cha_status == 20, success: scope.row.sccou_cha_status == 10 }">
                                {{ scope.row.sccou_cha_status == 10 && '已启用' || '已禁用' }}
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" min-width="26.36%">
                        <template slot-scope="scope">
                            <div class="operation-buttons size--2 flex-end" v-if="scope.row.sccou_cha_id > 0">
                                <el-button type="text" size="mini" v-if="scope.row.sccou_cha_title"
                                    @click="showDialog(scope.row)">
                                    <i class="iconfont add">&#xe603;</i>
                                    <span>添加节</span>
                                </el-button>
                                <el-button type="custom_primary" plain size="mini"
                                    @click="showDialog(scope.row, scope.row.sccou_cha_id)">
                                    编辑
                                </el-button>
                              <el-button type="custom_success" size="mini" plain
                                         v-if="scope.row.sccou_cha_pid !=0"
                                         @click="$router.push({ name: 'CONTENT_TEXTBOOK_TEATEACHING', params: { id: scope.row.sccou_cha_id } })">
                                备课
                              </el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!-- 编辑 || 新增 -->
        <el-dialog :title="dataForm.sccou_cha_id && '编辑' || '新增'" width="558px" :show-close="false"
            :visible.sync="dataDialog" @close="hideDialog">
            <div class="dialog-container">
                <el-form label-width="40px" ref="dataForm" :model="dataForm" :rules="rules">
                    <el-form-item label="章" prop="sccou_cha_title">
                        <el-input v-model.trim="dataForm.sccou_cha_title" maxlength="100" :readonly="isJoint" />
                    </el-form-item>
                    <el-form-item label="节" v-show="isJoint" prop="joint_title">
                        <el-input v-model.trim="dataForm.joint_title" maxlength="100" placeholder="请输入" />
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-switch v-model.trim="dataForm.sccou_cha_status" :active-value="10" :inactive-value="20"
                            :active-text="dataForm.sccou_cha_status == 10 && '启用' || '禁用'" />
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer">
                <el-button type="custom_info" size="small" @click="hideDialog">
                    取 消
                </el-button>
                <el-button type="custom_primary" size="small" :disabled="btnload" @click="addData" v-loading="btnload">
                    确 定
                </el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import { $chaptersList, $chaptersAdd, $chaptersEdit, $textbookInfo } from "@api/content"
export default {
    name: 'content_textbook_chapters',
    data() {
        let validateJointTitle = (rule, value, callback) => {
            if (this.isJoint && !value) {
                callback(new Error('请输入节'));
            } else {
                callback();
            }
        }
        return {
            textbookInfo: {}, // 教材信息
            tableData: [], // 章节数据
            isJoint: false, // 判断章节 true --> 节   false --> 章
            dataDialog: false,
            dataForm: {
                sccou_cha_status: 10,
                sccou_cha_title: '',
                joint_title: ''
            },
            btnload: false,
            /* 表单校验 */
            rules: {
                sccou_cha_title: [
                    { required: true, message: '请输入章', trigger: 'blur' },
                ],
                joint_title: [
                    { validator: validateJointTitle, trigger: 'blur' },
                ],
            }
        }
    },
    created() {
        this.getTextbook();
        this.getData();
    },
    methods: {
        /** 获取教材信息 */
        async getTextbook() {
            let { data } = await $textbookInfo(this.$route.params.id);
            this.textbookInfo = data;
            this.$forceUpdate();
        },
        /** 获取章节树状数据 */
        async getData() {
            let { data: res } = await $chaptersList(this.$route.params.id);
            res.data.map(item => {
                if (item.children.length) {
                    item.children.push({ sccou_cha_id: 0 - item.sccou_cha_id }) // 添加表格缺省行
                    item.children.forEach(citem => {
                        citem.cha_title = item.sccou_cha_title; // 修改节名称字段
                        citem.joint_title = citem.sccou_cha_title; // 修改节名称字段
                        delete citem.sccou_cha_title // 删除原节名称字段
                    })
                }
            })
            this.tableData = res.data;
        },
        /** 添加章节 */
        addData() {
            this.$refs.dataForm.validate(async (valid) => {
                if (valid) {
                    this.btnload = true;
                    if (this.dataForm.sccou_cha_id) return this.eidtData();
                    let params = {
                        sccou_id: this.$route.params.id,
                        sccou_cha_pid: this.isJoint && this.dataForm.sccou_cha_pid || 0,
                        sccou_cha_title: this.isJoint && this.dataForm.joint_title || this.dataForm.sccou_cha_title,
                        sccou_cha_status: this.dataForm.sccou_cha_status
                    }
                    let res = await $chaptersAdd(params);
                    this.btnload = false;
                    if (res) {
                        this.$message.success('添加成功');
                        this.hideDialog();
                        this.getData();
                    }
                }
            })
        },
        /** 编辑章 */
        async eidtData() {
            let params = {
                sccou_cha_id: this.dataForm.sccou_cha_id,
                sccou_cha_title: this.isJoint && this.dataForm.joint_title || this.dataForm.sccou_cha_title,
                sccou_cha_status: this.dataForm.sccou_cha_status
            }
            let res = await $chaptersEdit(params);
            this.btnload = false;
            if (res) {
                this.$message.success('编辑成功');
                this.hideDialog();
                this.getData();
            }
        },
        /**
         * 表格行类名动态添加
         * @param {object} row 表格行数据
         */
        rowClassName({ row }) {
            if (row.sccou_cha_id < 0) return 'empty';
        },
        /** 开启弹窗 */
        showDialog(data, id) {
            this.dataDialog = true;
            /* 父级操作弹窗 */
            if (data.sccou_cha_title && !data.sccou_cha_pid) {
                /* 判断是否带有id   若无,编辑父级本身; 若有,添加子级 */
                if (!id) {
                    this.isJoint = true;
                    this.dataForm.sccou_cha_pid = data.sccou_cha_id;
                }
                else this.dataForm.sccou_cha_status = data.sccou_cha_status
                this.dataForm.sccou_cha_title = data.sccou_cha_title;
            }
            /* 子级操作弹窗 */
            if (data.joint_title && data.sccou_cha_pid && id) {
                this.isJoint = true;
                this.dataForm.sccou_cha_title = data.cha_title;
                this.dataForm.joint_title = data.joint_title;
                this.dataForm.sccou_cha_status = data.sccou_cha_status
            }
            /* 存在id, 编辑或添加子级操作 */
            if (id) {
                this.dataForm.sccou_cha_id = data.sccou_cha_id;
            }
            console.log(this.dataForm);
        },
        /** 关闭弹窗 */
        hideDialog() {
            this.dataDialog = false;
            this.isJoint = false;
            this.resetForm('dataForm');
            this.dataForm = {
                sccou_cha_status: 10,
                sccou_cha_title: '',
                joint_title: ''
            };

        },
        /** 重置表单 */
        resetForm(ref) {
            this.$refs[ref].resetFields();
        }
    },
}
</script>